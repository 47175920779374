<template>
    <LoadingSpinner :showLoading="!chartInitialized"/>
    <div v-show='chartInitialized' style='position:relative;'>
         <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
		<Menu ref="menu" :model="moreMenuItems" :popup="true" />
        <div id='fund-sector-allocation-chart'
            data-qmod-tool='fundoverview'
            :data-qmod-params='fundSectorAllocationChartParams'
            class='fund-overview-qmod fund-sector-allocation-chart-qtool' ref="qmodDiv"></div>
    </div>
     <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'FundSectorAllocationChart',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    computed: {
        fundSectorAllocationChartParams() {
            return '{"symbol":"' + this.selectedSymbol + '"}'
        }
    },
    components: {
        LoadingSpinner,
        MoreOptionsMenu,
         SnapshotDialog
    },

     mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode( 
            `<div class="qmod-ui-tool qmod-funds-overview">
                    <div class="qmod-fund-stats pure-1"> 
                        <div class="qmod-fund-stats pure-1 qmod-divider">
                            <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
                            <div class="pure-u-sm-1-2" rv-if="scope.sectorData">
                                <h4 class="qmod-fund-subheading qmod-equity-h">
                                    <div class="pure-u-1-2">{'Sector Allocation' | i8ln tLang}</div>
                                    
                                </h4>
                                <div class="pure-u-1 qmod-sector-stat" rv-each-equity="scope.sectorData">
                                    <div class="qmod-fund-stat">
                                        <div class="pure-u-1-2 qmod-textl">
                                            <span class="fa qmod-equity-icon" rv-addclass="equity.icon"></span>
                                            <span>&nbsp;{equity.name| i8ln tLang}</span>
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{equity.percent}%</span>
                                            <span class="qmod-bar-wrap">
                                                <span class="qmod-bar-inner" rv-style="equity.percent| preFixWord 'width:'| postFixWord 'px;'"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pure-u-sm-1-2" rv-if="scope.geoData">
                                <h4 class="qmod-fund-subheading qmod-geo-h qmod-head-right">
                                    <div class="pure-u-1-2">{'Country Allocation' | i8ln tLang}</div>
                                    
                                </h4>
                                <div class="pure-u-1 qmod-geo-stat qmod-col-right" rv-each-loc="scope.geoData" rv-hide="loc.percent | = 0">
                                    <div class="qmod-fund-stat">
                                        <div class="pure-u-1-2 qmod-textl">
                                            <span>{loc.name | i8ln tLang}</span>
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{loc.percent}%</span>
                                            <span class="qmod-bar-wrap">
                                                <span class="qmod-bar-inner" rv-style="loc.percent| preFixWord 'width:'| postFixWord 'px;'"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pure-u-sm-1-2" rv-if="scope.fund.fundclassification.assetallocations">
                                  <h4 class="qmod-fund-subheading qmod-asset-h qmod-head-right">
                                      <div class="pure-u-1-2">Asset Allocation</div>
                                  </h4>
                                  <div class="pure-u-1-1 qmod-fund-asset qmod-col-right" rv-each-asset="scope.assetAllocation">
                                      <div class="qmod-fund-stat">
                                          <div class="pure-u-1-2 qmod-textl">
                                              <span>{asset.name}</span>
                                          </div>
                                          <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                              <span>{asset.y}%</span>
                                              <span class="qmod-bar-wrap">
                                                  <span class="qmod-bar-inner" rv-style="asset.y| preFixWord 'width:'| postFixWord 'px;'"></span>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`);

        tmplEle.appendChild(txt);

        let sectorAllocateChartDiv = this.$refs.qmodDiv;//document.getElementById('fund-sector-allocation-chart');
        sectorAllocateChartDiv.appendChild(tmplEle);
        sectorAllocateChartDiv.addEventListener('qmod-after-template-bind',  () => {
          console.debug('fund sector allocation chart template bound**************')
          this.chartInitialized = true;
          
      },false); //  {once:true}

    //   window.fundSectorAllocationChartQMod.loadTools();
    },

    activated() {
        console.debug(this.$options.name + " activated");
       
    },
    deactivated() {
        console.debug(this.$options.name + " deactivated");
        this.displaySnapshot = false;
    },

    data() {
      return {
          chartInitialized: false,
          displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],
      }
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementsByClassName('fund-sector-allocation-chart-qtool')[0];
            this.displaySnapshot = true;
        }
    }
}
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 0px;
    top: 0px;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-fund-subheading) {
    margin: 0px 0px 16px 0px;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-fund-subheading .pure-u-1-2) {
    font-size: 24px;
    font-weight: bold;
    color: #32364E;
    width: 100%;
}

::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-bar-wrap) {
    height: 18px;
    width: 100px;
    background: #f8f9fa;
    border-radius: 25px;
    overflow: hidden;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-bar-inner) {
    height: 18px;
    background: #32364e;
    border-radius: 25px;
}
::v-deep(.pure-u-sm-1-2) {
    width: 100%;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"]
    .qmod-funds-overview
    .qmod-fund-stats
    .qmod-col-right) {
    margin: 0;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-fund-stats .pure-u-sm-1-2:last-child),
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-fund-stats .pure-u-sm-1-2:last-child .pure-u-sm-1-2) {
    margin-top: 16px;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats) {
    margin-bottom: 0px;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-geo-stat),
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-asset),
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-sector-stat) {
    margin-bottom: 8px;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-geo-stat:nth-child(3)),
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-asset:last-child),
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-sector-stat:last-child) {
    margin-bottom: 0px;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-stat) {
    margin: 0px;
    line-height: 20px;
    padding: 0px;
    border-bottom: none;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-stat .qmod-textl) {
    width: 40%;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-stat .qmod-textl > span) {
    color: #32364E;
}
::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-fund-stats .qmod-fund-stat .qmod-textr) {
    width: 60%;
}
</style>