<template>
    <div >
      <keep-alive>
        <FundHoldingsVisuals :selectedSymbol='selectedSymbol' :key='selectedSymbol' v-observe-visibility="visibilityChanged"/>
      </keep-alive>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';

import FundHoldingsVisuals from '../QuoteMedia/FundHoldingsVisuals';

export default {
    name: "Holdings",
    data() {
		return {
			justMounted: false,
			active: false,
			showChart: false,
		}
	},
    components : {
		FundHoldingsVisuals
    },
    methods: {
        visibilityChanged (isVisible, entry) {
            console.debug("fund holdings visuals visibility set to  "+ isVisible);
            if(isVisible) {
                console.debug("fund holdings visuals gone visible "+ entry);
                 window.dispatchEvent(new Event('resize'));
            }
            
        }
    },
    computed: {
        ...mapState(['selectedFund']),
        selectedSymbol() {
            return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedFund);
        },
        
    },
     watch: {
      // selectedFund() {
      //   console.debug(this.$options.name + " selected fund watcher called");
              
      //     if( this.currentSymbol != this.selectedSymbol && this.$route.path =='/fund/holdings') {
      //       console.debug("about to call qmod loadtools");
      //       this.currentSymbol = this.selectedSymbol;    
      //       window.fundHoldingsQMod.loadTools();
      //     }         
        
      // },
      $route(newRoute, oldRoute) { //eslint-disable-line
        if( newRoute.fullPath =='/fund/holdings') {
           this.showChart = true;
            console.debug("in holdings route watcher");
           // if( !this.justMounted ) {
              
              if( this.currentSymbol != this.selectedSymbol) {
                
                  this.currentSymbol = this.selectedSymbol;
                  window.qMod.loadTool('fund-holdings-visuals');
                  
              } 
              // else {
              //   console.log("resizing holdings");
              //   window.dispatchEvent(new Event('resize'))
              // }
           // }
            //else {
          //    this.justMounted = false;
           // }
        }
        
      },
	},
  mounted() {
		console.debug(this.$options.name + " mounted");
		this.justMounted = true;
    this.currentSymbol = this.selectedSymbol;

    this.$nextTick(() => {
      window.qMod.loadTool('fund-holdings-visuals');
    });
	},
  
  // uncomment below if we start to actually cache this component, right now its not cached via the keep alive.
	// activated() {
	// 	this.active = true;
	// 	console.debug(this.$options.name + " activated");
	// 	if( !this.justMounted) {
  //           if(this.currentSymbol != this.selectedSymbol) {
  //               this.currentSymbol = this.selectedSymbol;
  //               window.fundHoldingsQMod.loadTools();
  //           }
  //       }
	// 	this.justMounted = false;
	// },
	// deactivated() {
  //   console.debug(this.$options.name + " deactivated");
	// 	this.justMounted = false;
	// 	this.active = false;
	// },
  unmounted() {
    console.debug(this.$options.name + " unmounted");
    this.showChart = false;
  }
}
</script>