<template>
  <div class="tab-content fund-overview-container">
				<div class="p-grid" >
					<div class="left-col p-col-12 p-md-7">
						<div class="card no-bottom-padding fund-overview-chart-card">
							<keep-alive>
								<SecurityOverviewChart :selectedFund="selectedFund" :selectedSymbol="selectedSymbol" :key="selectedSymbol" ref="overviewChart"/>
							</keep-alive>
						</div>

            <SubTabMenu :tabMenuItems="menu" v-if="isMobile()" />

            <template v-if='!isMobile()'>
              <div class='card investment-objective-container'>
                <div class='grid-header'>Investment Objective</div>
                <FundOverview :selectedSymbol="selectedSymbol" :key="selectedSymbol" extractOnly='Investment Objective'/>
              </div>
              <div class='card profile-container'>
                <div class='grid-header'>Overview</div>
                <FundOverview :selectedSymbol="selectedSymbol" :key="selectedSymbol" extractOnly='Fund Stats'/>
                <div class='grid-header'>Administration & Fees</div>
                <FundProfile :selectedSymbol="selectedSymbol" :key="selectedSymbol" />
              </div>
              <div class="card no-bottom-padding relative-performance-chart-container">
                <keep-alive>
                  <RelativePerformanceChart :selectedSymbol="selectedSymbol" :key="selectedSymbol" v-observe-visibility="visibilityChanged"/>
                </keep-alive>
              </div>
            </template>
					</div>
          
          <div class="right-col p-col-12 p-md-5">
            <template v-if='!isMobile()'>
              <div class="card fund-sector-allocation-chart-container">
                <keep-alive>
                  <FundSectorAllocationChart :selectedSymbol="selectedSymbol" :key="selectedSymbol"/>
                </keep-alive>
              </div>
              <div class='social-feed'>
                <keep-alive>
                  <FeedActivityList :selectedFund="selectedFund" :key="selectedFund" scrollStyle="width: 100% height: 1000px;"/>
                </keep-alive>
              </div>
            </template>
            <template v-else>
              <div v-show='selectedFeed == "overview"'>
                <div class="card">
                  <keep-alive>
                    <FundSectorAllocationChart :selectedSymbol="selectedSymbol" :key="selectedSymbol"/>
                  </keep-alive>
                </div>
                <div class="card card-sm">
                  <div class='grid-header'>Overview</div>
                  <FundOverview :selectedSymbol="selectedSymbol" :key="selectedSymbol" extractOnly='Fund Stats'/>
                </div>
                <div class="card card-sm">
                  <div class='grid-header'>Administration & Fees</div>
                  <FundProfile :selectedSymbol="selectedSymbol" :key="selectedSymbol" />
                </div>
                <div class="card relative-performance-chart-card">
                  <keep-alive>
                    <RelativePerformanceChart :selectedSymbol="selectedSymbol" :key="selectedSymbol" v-observe-visibility="visibilityChanged"/>
                  </keep-alive>
                </div>
              </div>
              <div v-show='selectedFeed == "social"'>
                <div class='social-feed'>
                  <keep-alive>
                    <FeedActivityList :selectedFund="selectedFund" :key="selectedFund" scrollStyle='width: 100%; height: 1400px'/>
                  </keep-alive>
                </div>
              </div>            
            </template>
				</div>
      </div>

    <AttributionFooter authors="quoteMedia" />
	</div>
</template>

<script>
import AttributionFooter from '../footer/AttributionFooter.vue';
import FeedActivityList from '../feed/FeedActivityList';
import FundOverview from '../../components/QuoteMedia/FundOverview';
import FundProfile from '../../components/QuoteMedia/FundProfile';
import FundSectorAllocationChart from '../QuoteMedia/FundSectorAllocationChart';
import SecurityOverviewChart from '../QuoteMedia/SecurityOverviewChart';
import RelativePerformanceChart from '../QuoteMedia/RelativePerformanceChart';
import SubTabMenu from '../menu/mobile/SubTabMenu.vue';

import { mapState } from 'vuex';

import BrowserUtils from '../../utilities/BrowserUtils';
import SecurityFormatter from '../../common/SecurityFormatter';

export default {
    name: "Overview",
    data() {
		return {
			justMounted: false,
			active: false,
      currentSymbol: null,
      selectedFeed: 'overview',
      menu: [
          {
              label: 'Overview',
              action: this.overviewClicked,
          },
          {
              label: 'Posts',
              action: this.socialFeedClicked,
          },
      ],
		}
	},
    components : {
      AttributionFooter,
      FundOverview,
      FundProfile,
      SubTabMenu,
      FeedActivityList,
      FundSectorAllocationChart,
      SecurityOverviewChart,
      RelativePerformanceChart
    },

    methods: {
      isMobile() {
        return BrowserUtils.isMobile();
      },

      visibilityChanged (isVisible, entry) { // eslint-disable-line
        if(isVisible) {
              window.dispatchEvent(new Event('resize'));
        }
      },
      socialFeedClicked() {
        this.selectedFeed = 'social';
      },
      overviewClicked() {
        this.selectedFeed = 'overview';
      },

      loadTools() {
        if( !this.isMobile() ){
          window.qMod.loadTool('fund-overview-investment-objective');
        }
        window.qMod.loadTool('fund-overview-fund-stats');
        window.qMod.loadTool('fund-profile');
        window.qMod.loadTool('relative-performance-chart');
        window.qMod.loadTool('fund-sector-allocation-chart');

      }
    },
    computed: {
      ...mapState(['selectedFund']),
      selectedSymbol() {
					return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedFund);
			},
    },
    watch: {
      selectedFund() {
        console.debug(this.$options.name + " selected fund watcher called");      
          if( this.currentSymbol != this.selectedSymbol && this.$route.path =='/fund') {
            console.debug("about to call qmod loadtools");
            this.currentSymbol = this.selectedSymbol;    
            // window.fundSectorAllocationChartQMod.loadTools();
            //window.securityOverviewChartQMod.loadTools();
            // window.relativePerformanceChartQMod.loadTools();
            // window.corporateEventsQMod.loadTools();
            // window.fundProfileQMod.loadTools();
            this.$nextTick(() => {
              this.loadTools();
            });
          }         
      },
      $route(newRoute, oldRoute) { //eslint-disable-line
        if( newRoute.fullPath =='/fund') {
            console.debug("in fund overview route watcher");
            // if( !this.justMounted && this.active) {
              if( this.currentSymbol != this.selectedSymbol) {
                  this.currentSymbol = this.selectedSymbol;
                  // window.fundSectorAllocationChartQMod.loadTools();
                  //window.securityOverviewChartQMod.loadTools();
                  // window.relativePerformanceChartQMod.loadTools();
                  // window.corporateEventsQMod.loadTools();
                  // window.fundProfileQMod.loadTools();
                  this.$nextTick(() => {
                    this.loadTools();
                  });
              }
              
            // }
            if( this.$refs.overviewChart){
              this.$refs.overviewChart.startPollingPriceChange();
            }
        }
        else{
          if( this.$refs.overviewChart){
            this.$refs.overviewChart.stopPollingPriceChange();
          }
        }
    },
	},

  mounted() {
		console.log(this.$options.name + " mounted");
		this.justMounted = true;
    this.currentSymbol = this.selectedSymbol;

    this.$nextTick(() => {
      this.loadTools();
    })
		
	},
	activated() {
		//console.debug("selected symbol from router param is = " + this.$route.params.selectedSymbol);
		this.active = true;
		console.log(this.$options.name + " activated");
		if( !this.justMounted) {
      if(this.currentSymbol != this.selectedSymbol) {
          this.currentSymbol = this.selectedSymbol;
          // window.fundSectorAllocationChartQMod.loadTools();
          //window.securityOverviewChartQMod.loadTools();
          // window.relativePerformanceChartQMod.loadTools();
          // window.corporateEventsQMod.loadTools();
          // window.fundProfileQMod.loadTools();
          this.$nextTick(() => {
            this.loadTools();
          });
      }
    }
		this.justMounted = false;
	},
	deactivated() {
    console.log(this.$options.name + " deactivated");
		this.justMounted = false;
		this.active = false;
	},
  unmounted() {
     console.log(this.$options.name + " unmounted");
  }
}
</script>

<style scoped>

.card {
  padding: 16px;
  margin-bottom: 16px;
}
.card.no-bottom-padding {
  padding-bottom: 0px;
}
.card:last-child {
  margin-bottom: 0px;
}

.left-col .card:last-child {
  padding-left: 0px;
}
.right-col .card:last-child {
  padding: 0px;
}

.grid-header {
  font-size: 24px;
  color: #32364e;
  margin-bottom: 8px;
  font-weight: bold;
}

.fund-profile-qtool ::v-deep(.p-grid > div) {
  padding-bottom: 0px;
}

@media (max-width: 760px) {
  .relative-performance-chart-card {
    padding: 30px 0px !important;
  }
	.card-sm {
		padding: 30px 16px !important;
		border-radius: 0;
	}
	.card {
    box-shadow: none;
		border-radius: 0;
    margin-bottom: 8px;
	}
	.company-overview {
		border-radius: 0;
    padding: 0;
	}
	.p-grid [class*=-col] .card:first-child {
		margin-top: 0px;
	}  
  .p-grid {
    margin: 0;
  }
  .tab-content {
    padding-top: 0px;
  }
  ::v-deep(.fund-overview-chart-card .advanced-txt),
  ::v-deep(.card .more-options) {
      display: none;
  }
  ::v-deep(.social-feed .card .more-options) {
      display: inline-flex;
  }

  ::v-deep(.qmod-funds-profile .p-grid),
  ::v-deep(.qmod-funds-overview .p-grid) {
    margin: 0px;
  }
  ::v-deep(.qmod-funds-profile .p-grid > div:first-child),
  ::v-deep(.qmod-funds-overview .p-grid > div:first-child) {
    padding: 16px 0px 0px;
  }
  ::v-deep(.qmod-funds-profile .p-grid > div:last-child),
  ::v-deep(.qmod-funds-overview .p-grid > div:last-child) {
    padding: 16px 0px 0px;
  }
  ::v-deep(.qmod-funds-profile .p-grid > div),
  ::v-deep(.qmod-funds-overview .p-grid > div) {
    width: 100%;
  }

  ::v-deep(.fund-sector-allocation-chart-qtool[data-qmod-tool="fundoverview"] .qmod-funds-overview .qmod-bar-wrap) {
    display: inline-block;
  }

  ::v-deep(.qmod-funds-performance .highcharts-axis-labels.highcharts-yaxis-labels text) {
    transform: translate(8px, 0px);
  }
}

</style>

