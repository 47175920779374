<template>
    <div>
    <LoadingSpinner :showLoading="!chartInitialized"/>
    <div v-show='chartInitialized' style='position: relative;'>
       <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
		<Menu ref="menu" :model="moreMenuItems" :popup="true" />
        <div id = 'fund-holdings-visuals' 
            data-qmod-tool="fundholdings"
            :data-qmod-params='fundHoldingsParams'
            class="fund-holdings-visuals-qtool" ref="qmodDiv"></div>
    </div>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />

    <AttributionFooter authors='quoteMedia' v-if='chartInitialized' />
    </div>
</template>

<script>
import AttributionFooter from '../footer/AttributionFooter.vue';
import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: 'FundHoldingsVisuals',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            chartInitialized: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],
        }
    },

    components: {
        AttributionFooter,
        LoadingSpinner,
        MoreOptionsMenu,
         SnapshotDialog
    },

    computed: {
        fundHoldingsParams() {
            return '{"symbol":"' + this.selectedSymbol + '"}';
        }
    },

    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode(
            `
            <div class="qmod-ui-tool qmod-fund-holdings">
   <div rv-unless="scope.error">
      
      <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
      <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
        
            
            <div class="qmod-chart-container" rv-unless="scope.noShareInfo | or | nq scope.validSymbols">
                <div class="card" style="min-height:400px;">
                   
                    
                    <div class="qmod-fund-head">
                        <h2 class="qmod-heading qmod-hbg">Fund Visualization</h2>
                        <div class="qmod-fund-stats pure-u-1 pure-u-md-1-2">
                        <div class="qmod-textr fund-score" rv-if="scope.fund.fundstatistics.qmfundscore" rv-hide="scope.fund.fundstatistics.qmfundscore | = 'NA'"><span rv-title="'qmfundrankblurb' | i8ln tLang" rv-on-mouseenter="scope.qmtooltip"><span>{'quotemedia fund score' | i8ln tLang}:&nbsp;</span><span class="fund-grade" rv-qmod-fundgrade="scope.fund.fundstatistics.qmfundscore" rv-hide="scope.fund.fundstatistics.qmfundscore | = 'NA'"></span></span></div>
                        <div class="qmod-textr fund-score" rv-if="scope.fund.fundstatistics.fundgrade" rv-hide="scope.fund.fundstatistics.fundgrade | = 'NA'"><span rv-title="'qmfundrankblurb' | i8ln tLang" rv-on-mouseenter="scope.qmtooltip"><span>{'quotemedia fund score' | i8ln tLang}:&nbsp;</span><span class="fund-grade" rv-qmod-fundgrade="scope.fund.fundstatistics.fundgrade"></span></span></div>
                        <div class="qmod-fund-riskrating qmod-textr" rv-hide="scope.fund.basicinfo.riskrating | = 'NA'"><span> {'risk rating' | i8ln tLang}:&nbsp;</span><span class="rating">{scope.fund.basicinfo.riskrating | i8ln tLang}</span></div>
                        <div class="qmod-commission-free qmod-badge-primary" rv-if="scope.fund.commissionfree"><span class="badge">{"Commission-Free" | i8ln tLang}</span></div>
                        <div class="hisa-wrap" rv-if="scope.fund.hisa">
                            <div class="rate-of-return"> <span>{"Rate of return" | i8ln tLang}:</span><strong>{scope.fund.hisacurrentyield}%</strong></div>
                            <div class="qmod-badge-primary" rv-if="scope.fund.hisa"><span class="badge">{"HISA Fund" | i8ln tLang}</span></div>
                        </div>
                        <div class="qmod-textr qmod-stylebox-wrap" rv-if="scope.fund.stylebox.styleboxassignment.equitystyleboxlong" rv-hide="scope.fund.stylebox.styleboxassignment.equitystyleboxlong | = 'NA'">
                            <span>{'Investment Style' | i8ln tLang}:&nbsp;</span><span>{scope.fund.stylebox.styleboxassignment.equitystyleboxlong | i8ln tLang}&nbsp;</span>
                            <div class="qmod-stylebox-con">
                                <div class="qmod-stylebox qmod-sb qmod-sb-1" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Large Value'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-2" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Large Blend'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-3" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Large Growth'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-4" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Mid Value'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-5" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Mid Blend'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-6" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Mid Growth'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-7" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Small Value'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-8" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Small Blend'" data-qmod-true-class="qmod-stylebox-fill"></div>
                                <div class="qmod-stylebox qmod-sb qmod-sb-9" rv-ifclass="scope.fund.stylebox.styleboxassignment.equitystyleboxlong |= 'Small Growth'" data-qmod-true-class="qmod-stylebox-fill"></div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="qmod-tab-container" style="right: 31px;">
                            
                        <ul class="qmod-tab-list">
                            <li class="qmod-tab-item" rv-class-qmod-active="scope.performanceShowing | = false">
                                <a class="qmod-tab-item-link" rv-on-click="scope.showPortfolio">
                                <div class="qmod-tab-text">{'Portfolio' | i8ln tLang}</div>
                                </a>
                            </li>
                            <li class="qmod-tab-item" rv-unless="scope.noShareInfo" rv-class-qmod-active="scope.performanceShowing">
                                <a class="qmod-tab-item-link" rv-on-click="scope.showPerformance">
                                <div class="qmod-tab-text">{'Performance' | i8ln tLang}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>


                    <div class="pure-u-1" rv-show="scope.performanceShowing" rv-unless="scope.noChartData">
                        <div class="qmod-portfolio-chart">
                            <div class="pure-g">
                                <div class="pure-u-1">
                                <div class="chart-loading qmod-loader-class" rv-show="scope.chartLoading">
                                    <div class="qmod-loader-threedot"></div>
                                </div>
                                <div class="qmod-panel qmod-chart-panel" rv-hide="scope.chartLoading">
                                    <div class="qmod-chart qmod-port-chart">
                                        <div class="qmod-port-chart-canvas"></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="qmod-performance-chart-container" rv-show="scope.performanceShowing | = false" rv-unless="scope.noChartData">
                        <div class="qmod-performance-chart">
                            <div class="pure-g">
                                <div class="pure-u-1">
                                <div class="chart-loading qmod-loader-class" rv-show="scope.chartLoading">
                                    <div class="qmod-loader-threedot"></div>
                                </div>
                                <div class="qmod-panel qmod-chart-panel" rv-hide="scope.chartLoading">
                                    <div class="qmod-chart qmod-per-chart" rv-hide="scope.noChartData">
                                        <div class="qmod-per-chart-canvas"></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pure-u-1">
                        <div class="qmod-chart-legend-container">
                            <div class="qmod-chart-legend qmod-portfolio-chart-legend" rv-html="scope.portfolioLegend"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pure-u-1 qmod-top-summary">
               <div class="pure-u-md-1-5 pure-u-sm-1-3 pure-u-1-2 ">
                  <div class="qmod-summary qm-s-1 card">
                     <div class="qmod-top-summary-h">{'Stock Holdings' | i8ln tLang}</div>
                     <div class="qmod-top-summary-stat">{scope.stockholdings | numeraljs '0,0.00' '0' tLang}%</div>
                  </div>
               </div>
               <div class="pure-u-md-1-5 pure-u-sm-1-3 pure-u-1-2 ">
                  <div class="qmod-summary qm-s-2 card">
                     <div class="qmod-top-summary-h">{'Bond Holdings' | i8ln tLang}</div>
                     <div class="qmod-top-summary-stat">{scope.bondholings | numeraljs '0,0.00' '0' tLang}%</div>
                  </div>
               </div>
               <div class="pure-u-md-1-5 pure-u-sm-1-3 pure-u-1-2 ">
                  <div class="qmod-summary qm-s-3 card">
                     <div class="qmod-top-summary-h">{'Cash Holdings' | i8ln tLang}</div>
                     <div class="qmod-top-summary-stat">{scope.cash | numeraljs '0,0.00' '0' tLang}%</div>
                  </div>
               </div>
               <div class="pure-u-md-1-5 pure-u-sm-1-3 pure-u-1-2 ">
                  <div class="qmod-summary qm-s-3 card">
                     <div class="qmod-top-summary-h">{'Other Holdings' | i8ln tLang}</div>
                     <div class="qmod-top-summary-stat">{scope.otherholdings | numeraljs '0,0.00' '0' tLang}%</div>
                  </div>
               </div>
               <div class="pure-u-md-1-5 pure-u-sm-1-3 pure-u-1-2 ">
                  <div class="qmod-summary qm-s-4 card">
                     <i class="fa fa-info-circle qmod-tooltip" rv-if="scope.note" aria-hidden="aria-hidden" rv-title="'holdingsnote' | i8ln tLang" rv-on-mouseenter="scope.qmtooltip"></i>
                     <div class="qmod-top-summary-h">{'Total of Portfolio in Top Holdings' | i8ln tLang}</div>
                     <div class="qmod-top-summary-stat">{scope.totalholdings | numeraljs '0,0.00' '0' tLang}%</div>
                  </div>
               </div>
            </div>

            <div class="qmod-no-chart-message" rv-if="scope.noShareInfo">{"no chart data exists for fund holdings" | i8ln tLang}</div>
           
            <div class="qmod-tab-container-bottom"></div>
            <div class="pure-u-1 card">
                <div class="pure-u-1">
                
                <div class="qmod-tab-container">
                    <h2 class="qmod-heading qmod-hbg">Top 10 Holdings</h2>
                    <ul class="qmod-tab-list">
                        <li class="qmod-tab-item" rv-class-qmod-active="scope.performanceShowing | = false">
                            <a class="qmod-tab-item-link" rv-on-click="scope.showPortfolio">
                            <div class="qmod-tab-text">{'Portfolio' | i8ln tLang}</div>
                            </a>
                        </li>
                        <li class="qmod-tab-item" rv-unless="scope.noShareInfo" rv-class-qmod-active="scope.performanceShowing">
                            <a class="qmod-tab-item-link" rv-on-click="scope.showPerformance">
                            <div class="qmod-tab-text">{'Performance' | i8ln tLang}</div>
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
               <div class="qmod-tool-wrap qmod-potfolio-table-con" rv-hide="scope.performanceShowing">
                  <table class="qmod-table qmod-potfolio-table nowrap" width="100%">
                     <thead>
                        <tr>
                           <th class="qmod-control"></th>
                           <th class="qmod-textl qmod-th-prt-0">{'Name' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-1">{'Symbol' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-2">{'weightpct' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-3">{'ytd' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-4">{'Shares' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-5">{'Market Val' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-6">{'pbratio' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-7">{'Currency' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-8">{'Country' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-prt-9">{'Sector' | i8ln tLang}</th>
                           <th class="qmod-textc qmod-th-10" rv-if="actionMenuOpts.showMenu">{'Actions' | i8ln tLang}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr rv-each-holding="scope.portfolio">
                           <td class="qmod-control"></td>
                           <td><span rv-unless="scope.longNameForwardURL">{holding.securityname}</span><a class="qmod-table-link" rv-if="scope.longNameForwardURL" rv-href="scope.longNameForwardURL | postFixWord holding.symbol">{holding.securityname}</a></td>
                           <td class="qmod-textr"><span rv-unless="scope.symbolForwardURL">{holding.symbol}</span><a class="qmod-table-link" rv-if="scope.symbolForwardURL" rv-href="scope.symbolForwardURL | postFixWord holding.symbol">{holding.symbol}</a></td>
                           <td class="qmod-textr" rv-data-sort="holding.weighting">{holding.weighting | numeraljs '0,0.00[a]' '&mdash;' tLang | postFix '%'}</td>
                           <td class="qmod-textr" rv-data-sort="holding.yeartodatepct"> {holding.yeartodatepct | numeraljs '0,0.00[a]' '&mdash;' tLang | postFix '%'}</td>
                           <td class="qmod-textr" rv-data-sort="holding.sharepercentage">{holding.sharepercentage | numeraljs '0,0.00[a]' '&mdash;' tLang | postFix '%'}</td>
                           <td class="qmod-textr" rv-data-sort="holding.marketvalue"> {holding.marketvalue | numeraljs '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.pbratio">{holding.pbratio | numeraljs '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr">{holding.currency}</td>
                           <td class="qmod-textr">{holding.country}</td>
                           <td class="qmod-textr qmod-tooltip" rv-title="holding.sector" rv-on-mouseenter="holding.sector"><i class="qmod-icon" rv-sector-icon="holding.sector"></i></td>
                           <td class="qmod-textc qmod-actions qmod-col-nopad qmod-td-10" rv-if="actionMenuOpts.showMenu">
                              <actionmenu actionMenuOpts="actionMenuOpts" quoteData="holding" forwardSymbol="holding.symbol"></actionmenu>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <button class="qmod-btn qmod-darkblue qmod-btn-sm qmod-read-more" rv-on-click="scope.readMore">{'more' | i8ln tLang}</button>
               </div>
               <div class="qmod-tool-wrap qmod-performance-table-con" rv-show="scope.performanceShowing">
                  <table class="qmod-table qmod-performance-table nowrap" width="100%">
                     <thead>
                        <tr>
                           <th class="qmod-control"></th>
                           <th class="qmod-textl qmod-th-per-0">{'name' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-1">{'symbol' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-2">{'last' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-3">{'change' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-4">{'changepct' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-5">{'1 Year Change' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-6">{'3 Year Change' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-7">{'5 Year Change' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-8">{'10 Year Change' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-9">{'marketcap' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-10">{'Yield' | i8ln tLang}</th>
                           <th class="qmod-textr qmod-th-per-11Avg">{'30D Vol' | i8ln tLang}</th>
                           <th class="qmod-textc qmod-th-12" rv-if="actionMenuOpts.showMenu">{'Actions' | i8ln tLang}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr rv-each-holding="scope.performanceData">
                           <td class="qmod-control"></td>
                           <td><span rv-unless="scope.longNameForwardURL">{holding.securityname}</span><a class="qmod-table-link" rv-if="scope.longNameForwardURL" rv-href="scope.longNameForwardURL | postFixWord holding.symbol">{holding.securityname}</a></td>
                           <td class="qmod-textr"><span rv-unless="scope.symbolForwardURL">{holding.symbol}</span><a class="qmod-table-link" rv-if="scope.symbolForwardURL" rv-href="scope.symbolForwardURL | postFixWord holding.symbol">{holding.symbol}</a></td>
                           <td class="qmod-textr" rv-data-sort="holding.last">{holding.last | asQHLast tLang '&mdash;'}</td>
                           <td class="qmod-textr" rv-data-sort="holding.change">{holding.change | numeraljsplus '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.changepercent"> {holding.changepercent | numeraljsplus '0,0.00[a]' '&mdash;' tLang | postFix '%'}</td>
                           <td class="qmod-textr" rv-data-sort="holding.weeks52change">{holding.weeks52change | numeraljsplus '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.year3change">{holding.year3change | numeraljsplus '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.year5change">{holding.year5change | numeraljsplus '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.year10change">{holding.year10change | numeraljsplus '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.marketcap">{holding.marketcap | numeraljs '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.yield">{holding.yield | numeraljs '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textr" rv-data-sort="holding.avgvol30days">{holding.avgvol30days | numeraljs '0,0.00[a]' '&mdash;' tLang}</td>
                           <td class="qmod-textc qmod-actions qmod-col-nopad qmod-td-10" rv-if="actionMenuOpts.showMenu">
                              <actionmenu actionMenuOpts="actionMenuOpts" quoteData="holding" forwardSymbol="holding.symbol"></actionmenu>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <button class="qmod-btn qmod-darkblue qmod-btn-sm qmod-read-more2" rv-on-click="scope.readMore2">{'more' | i8ln tLang}</button>
               </div>
            </div>
         </div>
         <div class="pure-u-1 text-secondary qmod-fdata-date" rv-if="scope.fundsDate">
            <div class="qmod-note">{'Funds data as of' | i8ln tLang} {scope.fundsDate}</div>
         </div>
      </div>
   </div>
   <div rv-if="scope.error"><span>{scope.error}</span></div>
</div>
            `
        //     `<div class="qmod-ui-tool qmod-fund-holdings">
        //       <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
        //       <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
        //           <div class="qmod-fund-head">
        //             <div class="card" rv-if="scope.noShareInfo">
        //              <div class="qmod-no-chart-message" rv-if="scope.noShareInfo">No chart data exists for fund holdings.</div>
        //              </div>
        //               <div class="qmod-chart-container" rv-unless="scope.noShareInfo">
        //                   <div class="card">
        //                     <div class="pure-u-1 row">
        //                         <h2 class="qmod-heading qmod-hbg">Fund Visualization</h2>
        //                         <div class="qmod-tab-container">
        //                             <ul class="qmod-tab-list">
        //                                 <li class="qmod-tab-item" rv-class-qmod-active="scope.performanceShowing">
        //                                     <a class="qmod-tab-item-link" rv-on-click="scope.showPerformance">
        //                                         <div class="qmod-tab-text">Returns</div>
        //                                     </a>
        //                                 </li>
        //                                 <li class="qmod-tab-item" rv-unless="scope.noShareInfo" rv-class-qmod-active="scope.performanceShowing | = false">
        //                                     <a class="qmod-tab-item-link" rv-on-click="scope.showPortfolio">
        //                                         <div class="qmod-tab-text">Holdings</div>
        //                                     </a>
        //                                 </li>
        //                             </ul>
        //                         </div>
        //                     </div>
        //                   <div class="pure-u-1" rv-show="scope.performanceShowing">
        //                       <div class="qmod-portfolio-chart">
        //                           <div class="pure-g">
        //                               <div class="pure-u-1">
        //                                   <div class="qmod-panel qmod-chart-panel">
        //                                       <div class="qmod-chart qmod-port-chart">
        //                                           <div class="qmod-port-chart-canvas"></div>
        //                                       </div>
        //                                   </div>
        //                               </div>
        //                           </div>
        //                       </div>
        //                   </div>
        //                     <div class="qmod-performance-chart-container" rv-show="scope.performanceShowing | = false">
        //                         <div class="qmod-performance-chart">
        //                             <div class="pure-g">
        //                                 <div class="pure-u-1">
        //                                     <div class="qmod-panel qmod-chart-panel">
        //                                         <div class="qmod-chart qmod-per-chart">
        //                                             <div class="qmod-per-chart-canvas"></div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                   </div>
        //                   <div class="pure-u-1 qmod-top-summary">
        //                       <div class="pure-u-sm-1-4 pure-u-1-2">
        //                           <div class="card qmod-summary qm-s-1">
        //                               <div class="qmod-top-summary-h">{'Stock Holdings' | i8ln tLang}</div>
        //                               <div class="qmod-top-summary-stat">{scope.stockholdings | numeraljs '0,0.00' '0'}%</div>
        //                           </div>
        //                       </div>
        //                       <div class="pure-u-sm-1-4 pure-u-1-2">
        //                           <div class="card qmod-summary qm-s-2">
        //                               <div class="qmod-top-summary-h">{'Bond Holdings' | i8ln tLang}</div>
        //                               <div class="qmod-top-summary-stat">{scope.bondholings | numeraljs '0,0.00' '0' }%</div>
        //                           </div>
        //                       </div>
        //                       <div class="pure-u-sm-1-4 pure-u-1-2">
        //                           <div class="card qmod-summary qm-s-3">
        //                               <div class="qmod-top-summary-h">{'Other Holdings' | i8ln tLang}</div>
        //                               <div class="qmod-top-summary-stat">{scope.otherholdings | numeraljs '0,0.00' '0'}%</div>
        //                           </div>
        //                       </div>
        //                       <div class="pure-u-sm-1-4 pure-u-1-2">
        //                           <div class="card qmod-summary qm-s-4">
        //                               <div class="qmod-top-summary-h">Weight of Top 10</div>
        //                               <div class="qmod-top-summary-stat">{scope.totalholdings | numeraljs '0,0.00' '0'}%</div>
        //                           </div>
        //                       </div>
        //                   </div>
        //                   <div class="pure-u-1">
        //                       <div class="qmod-chart-legend-container">
        //                           <div class="qmod-chart-legend qmod-portfolio-chart-legend" rv-html="scope.portfolioLegend"></div>
        //                       </div>
        //                   </div>
        //               </div>
        //               <div class="card bottom-container">
        //                 <div class="pure-u-1 row">
        //                      <h2 class="qmod-heading qmod-hbg">Top 10 Holdings</h2>
        //                     <div class="qmod-tab-container">
        //                         <ul class="qmod-tab-list">
        //                             <li class="qmod-tab-item" rv-class-qmod-active="scope.performanceShowing">
        //                                 <a class="qmod-tab-item-link" rv-on-click="scope.showPerformance">
        //                                     <div class="qmod-tab-text">Returns</div>
        //                                 </a>
        //                             </li>
        //                             <li class="qmod-tab-item" rv-unless="scope.noShareInfo" rv-class-qmod-active="scope.performanceShowing | = false">
        //                                 <a class="qmod-tab-item-link" rv-on-click="scope.showPortfolio">
        //                                     <div class="qmod-tab-text">Holdings</div>
        //                                 </a>
        //                             </li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //                 <div class="qmod-tab-container-bottom" style='margin-bottom: 0'></div>
        //                 <div class="pure-u-1">
        //                     <div class="qmod-tool-wrap qmod-potfolio-table-con" rv-hide="scope.performanceShowing">
        //                         <table class="qmod-table qmod-potfolio-table nowrap" width="100%">
        //                             <thead>
        //                                 <tr>
        //                                     <th class="qmod-control"></th>
        //                                     <th class="qmod-textl qmod-th-prt-0">{'Name' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-1">{'Symbol' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-2">{'weightpct' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-3">{'ytd' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-4">{'Shares' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-5">{'Market Val' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-6">{'pbratio' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-7">{'Currency' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-8">{'Country' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-prt-9">{'Sector' | i8ln tLang}</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr rv-each-holding="scope.portfolio">
        //                                     <td class="qmod-control"></td>
        //                                     <td>
        //                                         <span rv-unless="scope.longNameForwardURL">{holding.securityname}</span>
        //                                         <a class="qmod-table-link" rv-if="scope.longNameForwardURL" rv-href="scope.longNameForwardURL | postFixWord holding.symbol">{holding.securityname}</a>
        //                                     </td>
        //                                     <td class="qmod-textl">
        //                                         <span rv-unless="scope.symbolForwardURL">{holding.symbol}</span>
        //                                         <a class="qmod-table-link" rv-if="scope.symbolForwardURL" rv-href="scope.symbolForwardURL | postFixWord holding.symbol">{holding.symbol}</a>
        //                                     </td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.weighting">{holding.weighting}%</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.yeartodatepct"> {holding.yeartodatepct}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.sharepercentage">{holding.sharepercentage}%</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.marketvalue"> {holding.marketvalue}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.pbratio">{holding.pbratio}</td>
        //                                     <td class="qmod-textl">{holding.currency}</td>
        //                                     <td class="qmod-textl">{holding.country}</td>
        //                                     <td class="qmod-textl qmod-tooltip" rv-title="holding.sector" rv-on-mouseenter="holding.sector">
        //                                         <i rv-qmod-sector-icon="holding.sector"></i>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                         <button class="qmod-btn qmod-darkblue qmod-btn-sm qmod-read-more" rv-on-click="scope.readMore">More</button>
        //                     </div>
        //                     <div class="qmod-tool-wrap qmod-performance-table-con" rv-show="scope.performanceShowing">
        //                         <table class="qmod-table qmod-performance-table nowrap" width="100%">
        //                             <thead>
        //                                 <tr>
        //                                     <th class="qmod-control"></th>
        //                                     <th class="qmod-textl qmod-th-per-0">{'Name' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-1">{'Symbol' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-2">{'Last' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-3">{'change' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-4">{'changepct' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-5">{'1 Year Change' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-6">{'3 Year Change' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-7">{'5 Year Change' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-8">{'10 Year Change' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-9">{'marketcap' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-10">{'Yield' | i8ln tLang}</th>
        //                                     <th class="qmod-textl qmod-th-per-11Avg">{'30D Vol' | i8ln tLang}</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr rv-each-holding="scope.performanceData">
        //                                     <td class="qmod-control"></td>
        //                                     <td>
        //                                         <span rv-unless="scope.longNameForwardURL">{holding.securityname}</span>
        //                                         <a class="qmod-table-link" rv-if="scope.longNameForwardURL" rv-href="scope.longNameForwardURL | postFixWord holding.symbol">{holding.securityname}</a>
        //                                     </td>
        //                                     <td class="qmod-textl">
        //                                         <span rv-unless="scope.symbolForwardURL">{holding.symbol}</span>
        //                                         <a class="qmod-table-link" rv-if="scope.symbolForwardURL" rv-href="scope.symbolForwardURL | postFixWord holding.symbol">{holding.symbol}</a>
        //                                     </td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.last">{holding.last}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.change">{holding.change}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.changepercent"> {holding.changepercent}%</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.weeks52change">{holding.weeks52change}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.year3change">{holding.year3change}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.year5change">{holding.year5change}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.year10change">{holding.year10change}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.marketcap">{holding.marketcap}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.yield">{holding.yield}</td>
        //                                     <td class="qmod-textl" rv-data-sort="holding.avgvol30days">{holding.avgvol30days}</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                         <button class="qmod-btn qmod-darkblue qmod-btn-sm qmod-read-more2" rv-on-click="scope.readMore2">More</button>
        //                     </div>
        //                 </div>
        //             </div>
        //           </div>
        //           <div class="pure-u-1 qmod-textr qmod-fdata-date" rv-if="scope.fundsDate">
        //               <span>{'Funds data as of' | i8ln tLang} {scope.fundsDate}</span>
        //           </div>
        //       </div>
        //   </div>`
        )

        tmplEle.appendChild(txt);

        let fundHoldingsVisualsDiv = this.$refs.qmodDiv;// document.getElementById('fund-holdings-visuals');
        fundHoldingsVisualsDiv.appendChild(tmplEle);
        fundHoldingsVisualsDiv.addEventListener('qmod-after-template-bind', () => {
            console.debug('fund holdings visuals template bound**************')
            
            this.chartInitialized = true;
            /* Execute resize event at 3 seconds*/
            // setTimeout(() => { this.chartInitialized = true;}, 3000); 
        }, false)

        // window.fundHoldingsQMod.loadTools(); 
    },
    activated() {
        console.log(this.$options.name + " activated");
       
    },
    deactivated() {
        console.log(this.$options.name + " deactivated");
       this.displaySnapshot = false;
    },
    methods: {
         toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            if( document.getElementsByClassName('qmod-performance-chart-container')[0].getAttribute("style") && 
                document.getElementsByClassName('qmod-performance-chart-container')[0].getAttribute("style").indexOf("display: none;") != -1) {
                
                this.snapshotTarget = document.getElementsByClassName('qmod-portfolio-chart')[0];
            }
            else {
                this.snapshotTarget = document.getElementsByClassName('qmod-performance-chart-container')[0];
            }
            
            this.displaySnapshot = true;
        }
    }
}
</script>

<style scoped>
.more-options {
    position: absolute;
    right: 16px;
    top: 30px;
}

::v-deep(.qmod-ui-tool),
::v-deep(.pure-g [class*="pure-u"]) {
    font-family: "Trebuchet MS", Verdana !important;
    font-size: 12px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .card) {
    overflow: hidden;
    padding: 16px 16px 0px;
    margin-bottom: 16px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-chart-container) {
    height: auto;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .bottom-container) {
    padding: 16px 0px 0px;
    margin-bottom: 0px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] h2.qmod-heading) {
    padding: 0;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] h2.qmod-heading.qmod-hbg) {
    background-color: transparent;
    font-weight: bold;
    color:#32364e ;
    flex: 0 0 300px;
    font-size: 26px;
    margin-bottom: 20px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .bottom-container .pure-u-1.row) {
    padding: 0px 16px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-block-wrapper-body) {
    margin: 0;
}
/* ::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-tab-container) {
    width: 80%;
} */

/* highcharts grid stylings */
::v-deep(g.highcharts-legend) {
    transform: translate(32, 0)!important;
}
::v-deep(g.highcharts-legend .highcharts-legend-item text) {
    font-weight: normal !important;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line) {
    stroke-width: 1px;
    stroke:#BFBFBF;
    stroke-dasharray: 1,5;
}
/* Only show horizontal gridlines for holdings chart */
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-portfolio-chart .highcharts-grid.highcharts-xaxis-grid .highcharts-grid-line) {
    display: none;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-grid.highcharts-xaxis-grid .highcharts-grid-line) {
    stroke-width: 1px;
    stroke:#BFBFBF;
    stroke-dasharray: 1,5;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-axis-labels.highcharts-yaxis-labels tspan),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-axis-labels.highcharts-xaxis-labels tspan) {
    font-family: "Trebuchet MS", Verdana;
    color: #7F7F7F;
    fill: #7F7F7F;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-axis.highcharts-yaxis .highcharts-axis-title tspan),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .highcharts-axis.highcharts-xaxis .highcharts-axis-title tspan) {
    font-family: "Trebuchet MS", Verdana;
    fill: #32364e;
    color: #32364e;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-funds-overview .qmod-bar-wrap) {
    border-radius: 25px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .pure-u-1.row) {
    display: flex;
    align-items: center;
    padding: 0px 8px 0px 0px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-panel) {
    padding: 0px;
}

/* Toggle button stylings */
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-tab-container) {
    display: flex;
    /* right: 31px; */
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-tab-container .qmod-tab-list) {
    display: inline-block;
    /* padding: 0 31px 15px; */
    padding-bottom: 15px;
    margin-left: auto;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-tab-container .qmod-tab-list .qmod-tab-item) {
    list-style: none;
    display: inline-block;
    padding: 0;
    border-color: #32364e;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    text-align: center;
    color:#32364e;
    border-radius: 25px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-tab-container .qmod-tab-list .qmod-tab-item.qmod-active) {
    background-color: #32364e;
    border-bottom-width: 2px;
    border: 1px solid;
    color:#fff;
    border-radius: 25px;
    font-weight: normal;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-head .bottom-container .qmod-tab-container .qmod-tab-list) {
    padding: 0px 0px 16px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-head ) {
    display:flex;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .fund-score) {
    justify-content: center;
}

/* Summary holdings stylings */
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-top-summary) {
    margin: 0px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"]  .qmod-top-summary-h) {
    color:#32364e ;
    font-weight: 600;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-top-summary .qmod-summary) {
    border: none;
    border-radius: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    margin: 0px 16px 0px 0px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-top-summary > div:last-child .qmod-summary) {
    margin: 0px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-top-summary .qmod-summary .qmod-top-summary-h) {
    font-size: 20px;
    margin-bottom: 5px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-performance-chart .highcharts-label.highcharts-tooltip span),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-portfolio-chart .highcharts-label.highcharts-tooltip span) {
    font-family: "Trebuchet MS", Verdana !important;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-portfolio-chart .highcharts-label.highcharts-tooltip span table tr b) {
    font-weight: normal;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-performance-chart .highcharts-label.highcharts-tooltip span table tr th) {
    color: #32364e;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-performance-chart .highcharts-label.highcharts-tooltip span table tr:first-child h3) {
    font-size: 1.25rem;
}
::v-deep(.highcharts-tooltip-box) {
    stroke-width: 1px;
    font-size: 12px !important;
    opacity: 0.9;
    fill: #FFFFFF;
    stroke: #32364E;
}
::v-deep(.highcharts-tooltip text) {
    fill: #F0F0F0;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable tr:last-child) {
    border-bottom: none;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > thead > tr > th) {
    color: #999;
    font-weight: 500;
    padding: 12px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > thead > tr > th:after) {
    top: auto;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > tbody > tr > td.control:before, .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > tbody > tr > th.control:before) {
    color: #fff;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > tbody > tr > td) {
    padding: 12px 12px;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > thead > tr th:last-child:after) {
    right: 10%;
}
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > thead > tr th.qmod-performance-name),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > tbody > tr td.qmod-performance-name),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > thead > tr th.qmod-portfolio-name),
::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings table.qmod-table.dataTable.dtr-column > tbody > tr td.qmod-portfolio-name) {
    padding-left: 40px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-fdata-date) {
    margin-top: 8px;
}

::v-deep(.fund-holdings-visuals-qtool[data-qmod-tool="fundholdings"] .qmod-fund-holdings .qmod-fdata-date span) {
    margin-right: 20px;
}
</style>