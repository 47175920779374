<template>
    <LoadingSpinner :showLoading="!initialized"/>
    <div :id='customId'
        data-qmod-tool='fundoverview'
        :data-qmod-params='fundOverviewParams'
        class='fund-overview-qmod'
        v-show='initialized' ref="qmodDiv"></div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';

export default {
    name: 'FundOverview',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        },

        extractOnly: {
            type: String
        }
    },
    components: {
        LoadingSpinner
    },
    data() {
        return {
            initialized: false
        }
    },

    computed: {
        fundOverviewParams() {
            return '{"symbol":"' + this.selectedSymbol + '"}'
        },
        customId() {
            let baseId = 'fund-overview';

            if (this.extractOnly === 'Investment Objective') {
                baseId += '-investment-objective';
            } else if (this.extractOnly === 'Fund Stats') {
                baseId += '-fund-stats';
            }

            return baseId;
        }
    },

    mounted() {
        let txt = '';
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");

        if (this.extractOnly === 'Investment Objective') {
            txt = document.createTextNode(`
            <div class="qmod-ui-tool qmod-funds-overview">
                <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
                <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
                    <div class="qmod-fund-strat"> 
                        <div rv-html="scope.fund.basicinfo.prospectusobjective"> </div>
                    </div>
                </div>
            </div>
            `)
        } else if (this.extractOnly === 'Fund Stats') {
            txt = document.createTextNode(`
            <div class="qmod-ui-tool qmod-funds-overview">
                <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
                <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
                    <div class="p-grid">
                        <div class="p-md-6" rv-if="scope.fundstats |asArrayLength | &gt; 3">
                            <div rv-each-fundstat="scope.fundstats">
                                <div rv-if="fundstat.name | = 'Fund Category'">
                                    <div class="pure-u-1-2">
                                        <span>{fundstat.name  | i8ln tLang}:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                                <div rv-if="fundstat.name | = 'Market Value'">
                                    <div class="pure-u-1-2">
                                        <span>{fundstat.name  | i8ln tLang}:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                                <div rv-if="fundstat.name | = 'Turnover'">
                                    <div class="pure-u-1-2">
                                        <span>{fundstat.name  | i8ln tLang}:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div>
                            </div>
                            <div rv-if="scope.fundAssetInfo">
                                <div rv-each-fsi="scope.fundAssetInfo">
                                    <div rv-if="fsi.name | = 'TTM Yield'">
                                        <div class="pure-u-1-2">
                                            <span> {fsi.name | i8ln tLang}: </span>
                                        </div> 
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value"> 
                                            <span> {fsi.value} </span>
                                        </div> 
                                    </div>
                                </div>
                            </div> 
                        </div> 
                        <div class="p-md-6" rv-if="scope.fundstats |asArrayLength | &gt; 3">
                            <div rv-each-fundstat="scope.fundstats">
                                <div rv-if="fundstat.name | = 'Price to Book'">
                                    <div class="pure-u-1-2">
                                        <span>Price / Book Value:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                                <div rv-if="fundstat.name | = 'Price to Earnings'">
                                    <div class="pure-u-1-2">
                                        <span>Price / Earnings:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                                <div rv-if="fundstat.name | = 'Price to Revenue'">
                                    <div class="pure-u-1-2">
                                        <span>Price / Revenue:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                                <div rv-if="fundstat.name | = 'Price to Cash Flow'">
                                    <div class='pure-u-1-2'>
                                        <span>Price / Cash Flow:</span> 
                                    </div>
                                    <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                        <span>{fundstat.value}</span> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            `)
        } else {
            txt = document.createTextNode( 
                `<div class="qmod-ui-tool qmod-funds-overview">
                    <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div>
                    <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
                        <h4 class="qmod-fund-subheading"> 
                            <div class="pure-u-1-2"> {'Investment Objective' | i8ln tLang} </div>
                        </h4> 
                        <div class="qmod-fund-strat"> 
                            <div rv-html="scope.fund.basicinfo.prospectusobjective"> </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-md-6" rv-if="scope.fundstats |asArrayLength | &gt; 3">
                                <div rv-each-fundstat="scope.fundstats">
                                    <div rv-if="fundstat.name | = 'Fund Category'">
                                        <div class="pure-u-1-2">
                                            <span>{fundstat.name  | i8ln tLang}:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                    <div rv-if="fundstat.name | = 'Market Value'">
                                        <div class="pure-u-1-2">
                                            <span>{fundstat.name  | i8ln tLang}:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                    <div rv-if="fundstat.name | = 'Turnover'">
                                        <div class="pure-u-1-2">
                                            <span>{fundstat.name  | i8ln tLang}:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div>
                                </div>
                                <div rv-if="scope.fundAssetInfo">
                                    <div rv-each-fsi="scope.fundAssetInfo">
                                        <div rv-if="fsi.name | = 'TTM Yield'">
                                            <div class="pure-u-1-2">
                                                <span> {fsi.name | i8ln tLang}: </span>
                                            </div> 
                                            <div class="pure-u-1-2 qmod-textr qmod-stat-value"> 
                                                <span> {fsi.value} </span>
                                            </div> 
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                            <div class="p-md-6" rv-if="scope.fundstats |asArrayLength | &gt; 3">
                                <div rv-each-fundstat="scope.fundstats">
                                    <div rv-if="fundstat.name | = 'Price to Book'">
                                        <div class="pure-u-1-2">
                                            <span>Price / Book Value:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                    <div rv-if="fundstat.name | = 'Price to Earnings'">
                                        <div class="pure-u-1-2">
                                            <span>Price / Earnings:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                    <div rv-if="fundstat.name | = 'Price to Revenue'">
                                        <div class="pure-u-1-2">
                                            <span>Price / Revenue:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                    <div rv-if="fundstat.name | = 'Price to Cash Flow'">
                                        <div class='pure-u-1-2'>
                                            <span>Price / Cash Flow:</span> 
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr qmod-stat-value">
                                            <span>{fundstat.value}</span> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>`);
        }

        tmplEle.appendChild(txt);

        let fundOverviewDiv = this.$refs.qmodDiv;//document.getElementById(this.customId);
        fundOverviewDiv.appendChild(tmplEle);
        fundOverviewDiv.addEventListener('qmod-after-template-bind',  () => {
          console.debug('fund overview template bound**************')
          this.initialized = true;
          
      },false); //  {once:true}

    //   window.fundOverviewQMod.loadTools();
    }

}
</script>

<style scoped>
::v-deep(.qmod-ui-tool) {
    font-family: "Trebuchet MS", Verdana;
    width: 100%;
    max-width: unset;
}

::v-deep(.qmod-funds-overview .qmod-block-wrapper-body) {
    margin: 0px;
}

::v-deep(.qmod-funds-overview .qmod-textr) {
    color: black;
    line-height: 1.4;
}
::v-deep(.qmod-funds-overview .qmod-fund-strat) {
    color: black;
    line-height: 1.4;
    margin: 0px !important;
    padding: 0px !important;
}

::v-deep(.qmod-funds-overview .p-grid > div) {
    padding: 0px;
}
::v-deep(.qmod-funds-overview .p-grid > div:first-child) {
    padding: 16px;
}
::v-deep(.qmod-funds-overview .p-grid > div:last-child) {
    padding: 16px 16px 0px 0px;
}

::v-deep(.qmod-funds-overview .p-grid .pure-u-1-2) {
    line-height: 1.4;
}

</style>