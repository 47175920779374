<template>
    <LoadingSpinner :showLoading="!initialized"/>
    <div id='fund-profile'
        data-qmod-tool='fundprofile'
        :data-qmod-params='fundProfileParams'
        class='fund-overview-qmod'
        v-show='initialized' ref="qmodDiv"></div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner';

export default {
    name: 'FundProfile',
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    components: {
        LoadingSpinner,
    },
    data() {
        return {
            initialized: false,
        }
    },

    computed: {
        fundProfileParams() {
            return '{"symbol":"' + this.selectedSymbol + '"}'
        }
    },

    mounted() {

        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode( 
            `<div class="qmod-ui-tool qmod-funds-profile">
                <div class="qmod-invalid-symbol" rv-if="scope.invalidsymbol">{'invalidmix' | i8ln tLang}</div> 
                <div class="qmod-block-wrapper-body" rv-unless="scope.invalidsymbol">
                    <div class="p-grid">
                        <div class="p-md-6">
                            <div rv-if="scope.fundfees |asArrayLength|&gt;0">
                                <div class="qmod-rcol" rv-each-fee="scope.fundfees"> 
                                    <div rv-if="fee.name | = 'Management Fee %'">
                                        <div class="pure-u-1-2"> 
                                            <span > Management Fee: </span>
                                        </div> 
                                        <div class="pure-u-1-2 qmod-textr"> 
                                            <span>{fee.value}</span>
                                        </div> 
                                    </div>
                                    <div rv-if="fee.name | = 'Net Expense Ratio (MER)'">
                                        <div class="pure-u-1-2"> 
                                            <span > {fee.name | i8ln tLang}: </span>
                                        </div> 
                                        <div class="pure-u-1-2 qmod-textr"> 
                                            <span>{fee.value}</span>
                                        </div> 
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="p-md-6">
                            <div rv-if="scope.companyInfo|asArrayLength|&gt;0"> 
                                <div class="qmod-rcol" rv-each-fee="scope.companyInfo"> 
                                    <div rv-if="fee.name | = 'Company Name'">
                                        <div class="pure-u-1-2"> 
                                            <span> {fee.name}: </span>
                                        </div> 
                                        <div class="pure-u-1-2 qmod-textr"> 
                                            <span rv-qmod-truefalseconvert="fee.value"></span>
                                        </div> 
                                    </div>
                                </div>
                            </div> 
                            <div rv-if="scope.profileData|asArrayLength|&gt;0">
                                <div rv-each-pd="scope.profileData">
                                    <div rv-if="pd.name | = 'Inception Date'">
                                        <div class="pure-u-1-2">
                                            <span> {pd.name | i8ln tLang}: </span>
                                        </div>
                                        <div class="pure-u-1-2 qmod-textr">
                                            <span rv-qmod-truefalseconvert="pd.value"></span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>`);

        tmplEle.appendChild(txt);

        let fundProfileDiv = this.$refs.qmodDiv;//document.getElementById('fund-profile');
        fundProfileDiv.appendChild(tmplEle);
        fundProfileDiv.addEventListener('qmod-after-template-bind',  () => {
          console.debug('fund profile template bound**************')
          this.initialized = true;
          
      },false); //  {once:true}

    //   window.fundProfileQMod.loadTools();
    }
}
</script>

<style scoped>
::v-deep(.qmod-ui-tool) {
    font-family: "Trebuchet MS", Verdana;
    width: 100%;
    max-width: unset;
}

::v-deep(.qmod-funds-profile .qmod-block-wrapper-body) {
    margin: 0px;
}

::v-deep(.qmod-funds-profile .p-grid > div) {
    padding: 0px;
}
::v-deep(.qmod-funds-profile .p-grid > div:first-child) {
    padding: 16px 16px 0px;
}
::v-deep(.qmod-funds-profile .p-grid > div:last-child) {
    padding: 16px 16px 0px 0px;
}

::v-deep(.qmod-funds-profile .p-grid .pure-u-1-2) {
    line-height: 1.4;
}

::v-deep(.qmod-funds-profile .qmod-textr) {
    color: black;
}

::v-deep(.qmod-funds-profile .qmod-rcol) {
    padding-left: 0 !important;
}
</style>