<template>
    <div>
    <LoadingSpinner :showLoading="!chartInitialized"/>    
    <div v-show='chartInitialized' style='position: relative;'>
        <MoreOptionsMenu class='more-options' @click="toggleMenu"/>
		<Menu ref="menu" :model="moreMenuItems" :popup="true" />
        <div id='relative-performance-chart'
                data-qmod-tool='fundperformance'
                :data-qmod-params='relativePerformanceChartParams'
                class='fund-overview-qmod relative-performance-chart-qtool' ref="qmodDiv">
        </div>
    </div>
     <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="0" />
    </div>
</template>

<script>

import LoadingSpinner from '../common/LoadingSpinner';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import SnapshotDialog from '../modal/SnapshotDialog.vue';

export default {
    name: "RelativePerformanceChart",
    props: {
        selectedSymbol: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            chartInitialized: false,
            displaySnapshot: false,
            snapshotTarget: null,
            moreMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],
        }
    },
    computed: {
        relativePerformanceChartParams() {
            return '{"symbol":"'+this.selectedSymbol+'"}';
        }
    },

    components: {
        LoadingSpinner,
        MoreOptionsMenu,
        SnapshotDialog
    },

    mounted() {
        let tmplEle = document.createElement('script');
        tmplEle.setAttribute("type", "text/template");
        let txt = document.createTextNode( 
            `<div class="qmod-ui-tool qmod-funds-performance">
                            <div class="qmod-invalid-symbol" rv-if="scope.noData">{'Performance data is not available for this symbol.' | i8ln tLang}</div>
                            <div class="pure-u-1" rv-unless="scope.noData">
                                <h4 class="qmod-fund-subheading">
                                    <div class="pure-u-1-2">{'Relative Performance'}</div>
                                </h4>
                                <div class="qmod-fundperformance-chart-con">
                                    <div class="qmod-fundperformance-chart">
                                        <div class="fundperformance-chart"></div>
                                    </div>
                                </div>
                        </div>
                </div>`);

        tmplEle.appendChild(txt);

        let relativePerformanceChartDiv = this.$refs.qmodDiv;//document.getElementById('relative-performance-chart');
        relativePerformanceChartDiv.appendChild(tmplEle);
        relativePerformanceChartDiv.addEventListener('qmod-after-template-bind',  () => {
          console.log('relative performance chart template bound**************')
          this.chartInitialized = true;
          /* Execute resize event at 0 milliseconds - Helps run this event call asynchronously as quickly as possible 
          after rendering for the qmod is finished */
          setTimeout(() => {window.dispatchEvent(new Event('resize'))}) 
      },false); //  {once:true}
    
    //   window.relativePerformanceChartQMod.loadTools();
    },

    activated() {
        console.log(this.$options.name + " activated");
    },
    deactivated() {
        console.log(this.$options.name + " deactivated");
        this.displaySnapshot = false;
    },
    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        openSnapshotDialog() {
            this.snapshotTarget = document.getElementsByClassName('relative-performance-chart-qtool')[0];
            this.displaySnapshot = true;
        }
    }
}

</script>

<style scoped>
.more-options {
    position: absolute;
    right: 0px;
    top: 0px;
}

.qlogo {
  width:100%;
  height:100%;
  display:flex;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
}
.qlogow {
  margin: auto;
  opacity: 0.3;
}
.qlogowi {
  width: 100%
}
.relative-performance-chart-qtool {
    padding-right: 40px;
}
::v-deep(.fundperformance-chart),
::v-deep(.qmod-funds-performance .highcharts-container),
::v-deep(.qmod-funds-performance .highcharts-root) {
    overflow: visible !important;
}

::v-deep(.qmod-funds-performance .qmod-fund-subheading .pure-u-1-2) {
    font-family: "Trebuchet MS", "Verdana";
    font-size: 26px;
    font-weight: bold;
    color: #32364E;
    margin-left: 16px;
    width: 100%;
}

::v-deep(.qmod-funds-performance .highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line) {
    stroke-width: 1px;
    stroke:#BFBFBF;
    stroke-dasharray: 1,5;
}

::v-deep(.qmod-funds-performance .highcharts-axis.highcharts-xaxis .highcharts-tick) {
    display: none;
}
::v-deep(.qmod-funds-performance .highcharts-legend-item text),
::v-deep(.qmod-funds-performance .highcharts-axis-labels.highcharts-xaxis-labels text) {
    font-family: "Trebuchet MS", "Verdana";
}
::v-deep(.qmod-funds-performance .highcharts-axis-labels.highcharts-yaxis-labels text) {
    transform: translate(calc(100%), 0);
    font-family: "Trebuchet MS", "Verdana";
}
::v-deep(.qmod-funds-performance .highcharts-column-series .highcharts-data-label text) {
    display: none;
}
::v-deep(.highcharts-legend) {
    transform: translateX(+25px);
}
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-0 text),
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-1 text) {
    font-weight: normal !important;
}
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-0 path.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-series.highcharts-series-0 path.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-0 rect.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-series.highcharts-series-0 rect.highcharts-point) {
    fill: #33CC99;
}
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-1 path.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-series.highcharts-series-1 path.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-legend-item.highcharts-series-1 rect.highcharts-point),
::v-deep(.qmod-funds-performance .highcharts-series.highcharts-series-1 rect.highcharts-point)  {
    fill: rgba(230, 62, 62, 0.85);
}

::v-deep(.qmod-funds-performance .highcharts-tooltip .highcharts-tooltip-box:nth-of-type(1)), 
::v-deep(.qmod-funds-performance .highcharts-tooltip .highcharts-tooltip-box:nth-of-type(2)), 
::v-deep(.qmod-funds-performance .highcharts-tooltip .highcharts-tooltip-box:nth-of-type(3)) {
    /* display: none; */
}
::v-deep(.qmod-funds-performance .highcharts-tooltip .highcharts-tooltip-box) {
    opacity: 0.9;
    fill: #FFFFFF;
    stroke: #32364E;
}
::v-deep(.qmod-funds-performance .highcharts-label.highcharts-tooltip span) {
    font-family: "Trebuchet MS", "Verdana" !important;
    color: #32364E !important;
}
</style>