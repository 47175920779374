<template>

    <PillMenuBar :navigationItems="menuItems" headerType="main" :autoScrolling="true" class="p-d-md-flex p-d-none">
        <template #custom>
            <Button class='subscribe-alert-button purple-button__secondary' icon='pi pi-bell' @click="onCreateNewAlertClick" v-if="$store.getters['featureSettings']?.premiumSubscriptionEnabled"/>
            <Button :class="{'toggle-notes-only-button': true, 'selected': toggleNotesButtonSelected}" v-tooltip.bottom="'Notes Only'" icon='pi pi-pencil' v-show="$route.path =='/fund/notes'" @click="toggleNotesOnly"/>
            <FollowButton :followTarget="selectedFund"/>
        </template>
    </PillMenuBar>
    
    <ScrollPanel style="width:100%;" v-if="usageAllowed">
    <div class="tab-div">
        
    <router-view v-slot="{ Component }">

        <keep-alive><component :is="Component"/></keep-alive>

    </router-view>
    
    <Overview v-show="$route.path =='/fund' && usageAllowed"/>
    <Holdings v-show="$route.path == '/fund/holdings' && usageAllowed" />
    <Dividends v-show="$route.path === '/fund/dividends' && usageAllowed" />
    </div>
</ScrollPanel>
 <UsageLimitModal ref='usageLimitModal' :accessCheck="accessCheck"/>
<UpgradeToPremiumModal ref='upgradeToPremiumModal' :bodyText='bodyText' :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']"/>
<InputAlertModal ref='createNewAlertModal' :inputData="{'symbol': this.selectedFund.symbol, 'exchangeSymbol': this.selectedFund.exchangeSymbol, 'assetTypeId': 5}" />
</template>

<script>
import Overview from '../components/fund/Overview';
import Holdings from '../components/fund/Holdings';
import Dividends from '../components/fund/Dividends';
import PillMenuBar from '../components/menu/PillMenuBar';
import ScrollPanel from 'primevue/scrollpanel';
import FollowButton from '../components/button/FollowButton.vue';
import InputAlertModal from '../components/QuoteMedia/alerts/modal/InputAlertModal.vue';
import UpgradeToPremiumModal from '../components/modal/UpgradeToPremiumModal.vue';
import UsageLimitModal  from '../components/modal/UsageLimitModal.vue';


import { mapState } from 'vuex';
import {PREMIUM_SUBSCRIPTION_TYPES} from '../common/constants';
import UserUtils from '../utilities/UserUtils';
import EventBus from '../event-bus';
import UserService from '../service/UserService';

export default {
    name: 'Fund',
    data() {
        return {
            // CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            bodyText: "To get full access to create alerts and the other premium features below, upgrade to premium.",
            
            justMounted: false,
            active: false,
            menuItems: [
                {label: "Overview", to: '/fund'},
                {label: "Holdings", to: '/fund/holdings'},
                {label: "Charting", to: '/fund/charting'},
                {label: "Dividends", to: '/fund/dividends'},
                {label: "Posts", to: '/fund/notes'},
            ],

            toggleNotesButtonSelected: false,
            usageAllowed: false,
            accessCheck: null,
        }
    },
    components: {
        PillMenuBar,
        Dividends,
        Overview,//: defineAsyncComponent(() => import('../components/equity/Overview')),
        Holdings,
        FollowButton,
        InputAlertModal,
        ScrollPanel,
        UpgradeToPremiumModal,
        UsageLimitModal
    },
    created() {
    
    },
    computed: {
        ...mapState(['selectedFund'])
    },
    
    mounted() {
        this.justMounted = true;
        
        try {
            if (this.$route.fullPath === '/fund') {
                EventBus.emit('track-overview');
            }
        } catch (err) {
            return;
        }


    },
    activated() {
        this.active = true;
        console.debug("activated Fund");
        // if( !this.justMounted && this.$route.params.selectedSymbol) {
        //  this.setSelectedSymbolFromQM(this.$route.params.selectedSymbol);
        // }
        this.justMounted = false;

        if( this.selectedFund ){
            UserService.checkAnalysisToolsUsageRate("funds", this.selectedFund.fundId).then( resp => {
                if( resp.data.status == 'error') {
                    this.accessCheck = resp.data.accessCheck;
                    this.$refs.usageLimitModal.open();
                    
                }
                else {
                    this.usageAllowed = true;
                    this.accessCheck = null;
                }
            })
        }
    },
    deactivated() {
        console.debug("deactivated Fund");
        this.justMounted = false;
        this.active = false;
    },
    
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
    // async beforeRouteUpdate(to, from) {
    //  this.post = null
    //  try {
    //  this.post = await getPost(to.params.id)
    //  } catch (error) {
    //  this.error = error.toString()
    //  }
    // },

    watch: {
        $route() {
            try {
                EventBus.emit('track-fundNav', this.$route.fullPath)
            } catch (err) {
                return;
            }
        },

        selectedFund(newVal, oldVal) {//eslint-disable-line
            if( newVal ) {
                this.usageAllowed = false;
                console.log("selectedFund changed to ", newVal)
                UserService.checkAnalysisToolsUsageRate("funds", this.selectedFund.fundId).then( resp => {
                    if( resp.data.status == 'error') {
                         this.accessCheck = resp.data.accessCheck;
                         this.$refs.usageLimitModal.open();

                    }
                    else {
                        this.usageAllowed = true;
                        this.accessCheck = null;
                    }
                });
                    
            }
        }
    },


    methods: {
        toggleNotesOnly() {
            this.toggleNotesButtonSelected = !this.toggleNotesButtonSelected;

            EventBus.emit('toggle-notes-only-button');
        },
        
        onCreateNewAlertClick() {
            if (!this.$store.getters['users/featureList'].includes('Alerts') && !UserUtils.isPremiumSubscriber()) {
                this.$refs.upgradeToPremiumModal.open();
            } else {
                this.$refs.createNewAlertModal.open();
            }
        }
    }
    
}
</script>

<style scoped>
 .pill-menu-bar {
    margin-left: 1rem;
    width: calc(100% - 2rem);
 }

 .tab-div {
    /* background: #1f2d40; */
    padding: 0 1rem 1rem;
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
 }

 ::v-deep(.subscribe-alert-button) {
    border-radius: 50px;
}

::v-deep(.toggle-notes-only-button) {
    border-radius: 16px;
    padding: 6px 16px;
    border: 2px solid #32364e;
    background: #FFFFFF;
    color: #32364e;
}
::v-deep(.toggle-notes-only-button:enabled:hover),
::v-deep(.toggle-notes-only-button.selected) {
    background: #32364e;
    color: #FFFFFF;
    border: 2px solid #32364e;
 }
 ::v-deep(.toggle-notes-only-button:focus) {
    border-color: #32364e;
    box-shadow: none;
 }

 ::v-deep(.follow-button) {
    padding: 8px 16px;
    border-width: 2px;
    border-style: solid;
    border-radius: 28px;
}

.p-scrollpanel ::v-deep(.p-scrollpanel-content) {
    overflow-x: hidden;
}
@media (max-width: 760px) {
    .tab-div {
        margin-top: 8px;
        padding: 0px;
    }
}
</style>